<template>
    <div>
        <jy-query ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="辖区查询纬度">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="月份">
                <el-date-picker
                    type="month"
                    value-format="yyyy-MM"
                    placeholder="选择月"
                    v-model="form.dateStr"
                    :clearable="false"
                ></el-date-picker>
            </jy-query-item>
            <jy-query-item label="固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option
                        v-for="t in wasteTypes"
                        :key="t.wasteTId"
                        :label="t.cName"
                        :value="t.wasteTId"
                    ></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <export-excel fileName="委托订单" url fileType=".xls" size="small"></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>搜索结果合计：记录{{ total }}条，清运重量{{ statTotal.sum }}kg，上一年同期清运重量{{ statTotal.sumPre }}kg，与上一年同期变化{{ formatPercent(statTotal.percent) }}%，入库量{{ statTotal.inStockSum }}kg，处置净重{{ statTotal.delSum }}kg</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index" width="55"></jy-table-column>
                <jy-table-column label="省" min-width="120">
                    <template v-slot="scope">{{ scope.row.province ? scope.row.province.regionNa : '-' }}</template>
                </jy-table-column>
                <jy-table-column label="市" min-width="120" v-if="form.level>=2">
                    <template v-slot="scope">{{ scope.row.city ? scope.row.city.regionNa : '-' }}</template>
                </jy-table-column>
                <jy-table-column label="区县" min-width="120" v-if="form.level>=3">
                    <template v-slot="scope">{{ scope.row.district ? scope.row.district.regionNa : '-' }}</template>
                </jy-table-column>
                <jy-table-column label="街道/镇/乡" min-width="120" v-if="form.level==4">
                    <template v-slot="scope">{{ scope.row.town ? scope.row.town.regionNa : '-' }}</template>
                </jy-table-column>
                <jy-table-column label="固废类别" min-width="120">{{ getWasteTNa }}</jy-table-column>
                <jy-table-column label="统计时间" min-width="120">
                    <template v-slot="scope">{{ scope.row.dateStr }}</template>
                </jy-table-column>
                <jy-table-column :label="`清运重量(${ unit })`" sortable prop="number" min-width="150">
                    <template v-slot="scope">{{ scope.row.sum }}</template>
                </jy-table-column>
                <jy-table-column :label="`上一年周期(${ unit })`" min-width="150">
                    <template v-slot="scope">{{ scope.row.sumPre }}</template>
                </jy-table-column>
                <jy-table-column label="与上一年周期变化" sortable prop="number" min-width="160">
                    <template v-slot="scope">{{ scope.row.percent }}%</template>
                </jy-table-column>
                <jy-table-column :label="`入库量(${ unit })`" sortable prop="number" min-width="130">
                    <template v-slot="scope">{{ scope.row.inStockSum }}</template>
                </jy-table-column>
                <jy-table-column :label="`处置净重(${ unit })`" sortable prop="number" min-width="150">
                    <template v-slot="scope">{{ scope.row.delSum }}</template>
                </jy-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>
        <region-tree
            ref="region"
            @select="addRegion"
            check-strictly
            @change="checkChange"
        ></region-tree>
    </div>
</template>

<script>
import ExportExcel from "@/components/pages/admin/common/export";
import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
import { MessageBox } from "element-ui";
export default {
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.initTime();
            this.initRegion();
            await this.getWasteTypeList();
            this.getStat();
            this.getList();
        },
        selectRegion() {
            this.$refs.region.init({
                pid: this.pid,
                check: this.check
            });
        },
        initTime() {
            const d = new Date();
            const year = d.getFullYear().toString();
            const month = (d.getMonth() + 1).toString().padStart(2, '0');
            this.form.dateStr = `${year}-${month}`;
        },
        initRegion() {
            const { regionId, regionLevel, regionNa } = this.$store.state.info;
            if (regionId && regionLevel) {
                this.pid = regionId;
                this.form.regionIds = [regionId];
                this.form.level = regionLevel;
                this.form.regionNa = regionNa;
                this.check = true;
            }
        },
        oncheck() {
            this.pageIndex = 1;
            this.getStat();
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$http
                .post('/jurisdiction/waste/monthStat', {
                    pageSize: this.pageSize,
                    pageIndex: this.pageIndex,
                    ...this.form,
                })
                .then(res => {
                    this.dataList = res.detail.list;
                    for (let node of this.dataList) {
                        node.disabled = true;
                    }
                    this.total = res.detail.total;
                    this.unit = this.wasteTypes.find(each => each.wasteTId === this.form.wasteTId).unit;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getStat() {
            this.$http
                .post('/jurisdiction/waste/monthStatTotal', {
                    ...this.form,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                })
                .then(res => {
                    this.statTotal = res.detail;
                })
        },
        async getWasteTypeList() {
            return this.$http
                .post("/wasteType/listAll")
                .then(res => {
                    this.wasteTypes = res.detail;
                    this.form.wasteTId = this.wasteTypes[0].wasteTId;
                    this.unit = this.wasteTypes[0].unit;
                });
        },
        add() {
            this.$emit("switch");
        },
        addRegion(data) {
            if (data.length) {
                this.form.regionIds = data.map(v => v.regionId);
                this.form.regionNa = data.map(v => v.regionNa).join('/');
                this.form.level = data[0].level;
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.pageIndex = 1;
            this.form.regionIds = [];
            this.form.regionNa = "";
            this.init();
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getList();
        },
        formatPercent() {
            const percent = this.statTotal.percent * 100;
            if (percent > 0) {
                return '+' + percent;
            }
            return percent.toString();
        },
        checkChange(ref, node, check) {
            if (node.level === 0) {
                ref.setChecked(node.regionId, false, false);
            }

            const checkedNodes = ref.getCheckedNodes().filter(v => v.regionId !== node.regionId);
            console.log(checkedNodes, node);
            if (checkedNodes.length && check) {
                checkedNodes.forEach(v => {
                    if (v.regionId !== node.regionId && v.level !== node.level) {
                        ref.setChecked(node.regionId, false, false);
                        MessageBox({
                            title: '提示',
                            message: '请选择同级节点'
                        })
                        return;
                    }
                })
            }
        }
    },
    computed: {
        getWasteTNa() {
            const wasteType = this.wasteTypes.find(v => v.wasteTId === this.form.wasteTId);
            if (wasteType) {
                return wasteType.cName;
            }
            return '';
        }
    },
    data() {
        return {
            loading: false,
            dataList: [],
            total: 0,
            pid: 0,
            check: true,

            form: {
                dateStr: null,
                wasteTId: null,
                regionIds: [],
                regionNa: null,
                level: null,
            },

            statTotal: {},
            unit: '',
            wasteTypes: [],
            industryList: [],

            pageIndex: 1,
            pageSize: 10
        };
    },
    components: {
        ExportExcel,
        RegionTree
    }
};
</script>
<style scoped>
.query_area {
    margin-top: 10px;
}
</style>
